import Pagepart from "../Pagepart/Pagepart";
import { NavLink } from 'react-router-dom';

function Footer(props){
    return (
    <>
        <Pagepart title="PATTON Company"
                        titeFontSize="16pt"
                        bodyText={
                                <>
                                        <NavLink to="/Contact">Contact Us</NavLink>
                                </>
                        }
                        color="white"
                        backgroundColor="black" 
                        height="6vh"
                        paddingTop="2vh"
                        padding="2vh"
                        fontSize="8pt"/>
                <Pagepart title=""
                        bodyText="Icons by Hilmy Abiyyu As'ad on freeicons.io"
                        color="white"
                        backgroundColor="black" 
                        height="3vh"
                        paddingTop=""
                        fontSize="8pt"/>    
                <Pagepart title=""
                        bodyText="© 2021 PATTON Company (Pty) Ltd."
                        color="white"
                        backgroundColor="black" 
                        height="7vh"
                        paddingTop="0vh"
                        fontSize="8pt"/>    
                        
    </>
        
    );
}

export default Footer;