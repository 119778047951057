import ContentSection from '../contentSection/ContentSection';
import React from "react";
import { useMediaPredicate } from "react-media-hook";

function Section(props) {
    
    var biggerThan400 = useMediaPredicate("(min-width: 800px)");   
    var sectionHeight = '60vh';
    if(props.sectionHeight) 
    {
        sectionHeight = props.sectionHeight;
    }
    if(biggerThan400)
    {
        return(
        <div  style={{  height: sectionHeight, 
                        backgroundImage: `url(${props.background})`, 
                        backgroundSize:'cover', 
                        backgroundRepeat: 'no-repeat', 
                        width: '100%' 
                    }} >
            <div id="top-spacer" style={{ height: '5vh' }} />
            <div className="Section"  style={{  
                                                width: '40vw', height: '18vw', 
                                                marginLeft: '5vw' }}>
            <div>
                <div style={{ height: '2em', textAlign: "center", backgroundColor: 'white', borderTop: props.borderTop }} />
                    <ContentSection title={props.title}
                                    bodyText= {
                                        <div style={{ backgroundColor: 'white' }}>
                                            <div style={{ letterSpacing:'0.1em', lineHeight: '1.3em' , paddingTop: '10px'}}>
                                                {props.middleSectionText}
                                            </div>
                                            <div style={{fontFamily:'helvetica', fontSize:'16pt', paddingTop: '20px' }}>
                                            {props.lowerSectionHeading}
                                            </div>
                                            <div style={{ paddingTop: '10px', letterSpacing:'0.1em', lineHeight: '1.3em' }}>
                                            {props.lowerSectionText}
                                            </div>
                                            <div>
                                                { props.bottomSection }
                                            </div>
                                        </div>
                                    }/>

            </div>
        </div>
    </div>      
        );
    }
    else {
        return( <div/>);
    }
}

export default Section;