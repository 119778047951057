import React from 'react';
import Header from '../../Header.js';
import Pagepart from '../Pagepart/Pagepart.js';
import Section from '../section/Section.js';
import background from '../../assets/website-chess-background-4.png';
import consultingIcon from '../../assets/Consulting-icon.png';
import bookIcon from '../../assets/Book-icon.png';
import mentorshipIcon from '../../assets/Coaching-icon.png';
import staircaseImage from '../../assets/staircase-cropped.jpg'
import bookImage from '../../assets/book-cropped.jpg'
import { NavLink } from 'react-router-dom';
import Footer from '../Footer/Footer.js';
import { useMediaPredicate } from "react-media-hook";

function Home() {
  var onDesktop = useMediaPredicate("(min-width: 400px)");   
  if(onDesktop)
  {
    {/*
      RESPONSIVE DESKTOP SECTION FOLLOWS
    */}
    return (
      <>
        <Header />
        <Section    background={background}
                        title="Architecture Consulting, Coaching & Learning"
                      borderTop = "solid 2px red"

                        middleSectionText={ 
                          <div style={{ textAlign: 'center' }}>
                          Empower senior Developers with the knowledge and practical hands-on skills to confidently step into the Architect role.
                          </div>
                        }
                        lowerSectionHeading="" 
                        lowerSectionText=""
                        bottomSection={ 
                          <div style={{ textAlign: 'center' }}>
                            <NavLink to="/Consulting">
                              <img title="Consulting" width='50px' src={consultingIcon} />
                            </NavLink>
                            <NavLink to="/Coaching">
                              <img title="Coaching" style={{ marginLeft: '5vw' }} width='50px' src={mentorshipIcon} />
                            </NavLink>
                            <NavLink to="/KnowledgeTransfer">
                              <img title="Learning" style={{ marginLeft: '5vw' }} width='50px' src={bookIcon} />
                            </NavLink>
                          </div>
                         } />
        <div>
          
          <table >
            <tbody>
              <tr>
                <td><div id='spacerDive' style={{ height: '4vh' }}/></td>
              </tr>
              <tr>
                <td>
                  <img title="Learning" style={{ marginLeft: '5vw' }} width='200px' src={staircaseImage} />
                </td>
                <td >
                         
                  <div  >
                    
                  <Pagepart titeFontSize = "20pt" 
                      fontSize = "14pt"
                      title = "Software architecture consulting services" 
                      width = "25vw"
                      footerPadding = "4vh"
                      borderBottom = "1px solid"
                      bodyText={ 
                          <div>
                            Patton Co. offers comprehensive Software Architecture consulting services that deliver best of breed software-design and world class software. <NavLink to="/Consulting" 
                                activeStyle={{ color: "Gray" }}
                                style={{ textDecoration: 'none', cursor: 'pointer', color: 'grey' }} >
                            <span style={{ textDecoration: 'underline' }}>Learn more.</span>
                          </NavLink> 
                        </div>
                          } />
                  </div>
                </td>
                <td>
                  <img title="Learning" style={{ marginLeft: '5vw' }} width='200px' src={bookImage} />
                </td>
                <td>
                  <Pagepart titeFontSize="20pt"
                      title = "Architecture learning founded on Coaching"
                      fontSize = "14pt"
                      width = "25vw"
                      borderBottom = "1px solid"
                      bodyText={
                          <div>Our Coaching and Learning service is conducted by a deeply skilled and experienced Architect who
                            empowers senior Developers with the knowledge and practical hands-on skills to take the next step in their careers. <NavLink to="/Coaching" 
                                activeStyle={{ color: "Gray" }}
                                style={{ textDecoration: 'none', cursor: 'pointer', color: 'grey' }} >
                            <span style={{ textDecoration: 'underline' }}>Learn more.</span>
                          </NavLink> 
                          </div>   
                            } 
                      />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <div style={{ paddingTop: '50px' }}></div>
        <Footer />
      </>
    );
  }
  else
  {
    {/*
      RESPONSIVE MOBILE SECTION FOLLOWS
    */}
    return (
      <>
        <Header />
        <div>
          
          <table >
            <tbody>
              <tr>
                <td><div id='spacerDive' style={{ height: '4vh' }}/></td>
              </tr>
              <tr>
                <td >
                         
                  <div  >
                  <img title="Learning" style={{ marginLeft: '5vw' }} width='300px' src={staircaseImage} />  
                  <Pagepart titeFontSize = "20pt" 
                      fontSize = "14pt"
                      title = "Software architecture consulting services" 
                      width = "75vw"
                      footerPadding = "4vh"
                      borderBottom = "1px solid"
                      bodyText={ 
                          <div>
                            Patton Co. offers comprehensive Software Architecture consulting services that deliver best of breed software-design and world class software. <NavLink to="/Consulting" 
                                activeStyle={{ color: "Gray" }}
                                style={{ textDecoration: 'none', cursor: 'pointer', color: 'grey' }} >
                            <span style={{ textDecoration: 'underline' }}>Learn more.</span>
                          </NavLink> 
                        </div>
                          } />
                  </div>
                </td>
               
              </tr>
              <tr>
              <td>
                  <img title="Learning" style={{ marginLeft: '5vw' }} width='300px' src={bookImage} />
                  <Pagepart titeFontSize="20pt"
                      title = "Architecture learning founded on Coaching" 
                      fontSize = "14pt"
                      width = "75vw"
                      borderBottom = "1px solid"
                      bodyText={
                          <div>Our Coaching and Learning service is conducted by a deeply skilled and experienced Architect who
                            empowers senior Developers with the knowledge and practical hands-on skills to take the next step in their careers. <NavLink to="/Coaching" 
                                activeStyle={{ color: "Gray" }}
                                style={{ textDecoration: 'none', cursor: 'pointer', color: 'grey' }} >
                            <span style={{ textDecoration: 'underline' }}>Learn more.</span>
                          </NavLink> 
                          </div>   
                            } 
                      />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <div style={{ paddingTop: '50px' }}></div>
        <Pagepart titeFontSize = "20pt" 
                      fontSize = "14pt"
                      title = "" 
                      footerPadding = "12.2vh"
                  bodyText={
                    <>
     
                    </>
                  }/>
        <Footer />
      </>
    );
  }
    
}

export default Home;