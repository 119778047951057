
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { BrowserRouter as NavRouter } from 'react-router-dom' ;

ReactDOM.render(
  <NavRouter>
    <App />
  </NavRouter>,
  document.getElementById('root')
);
