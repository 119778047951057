import React from 'react';
import codeImg from '../../assets/code-1839406_1920.png';
import progressBar from '../../assets/under-construction-scrollbar.svg';
import stagLogoImg from '../../assets/Stag-Logo v20-long.png'

function SiteDown() {
    return (
        <div>
            <table style={{ backgroundImage:`url(${codeImg})`, letterSpacing: '0.4em' ,  fontFamily:'helvetica', fontSize: '30pt', verticalAlign: 'middle', width:'100VW', height: '100VH'}}>
                <tbody>
                    <tr>
                        <td>
                            <table style={{ textAlign: 'center', width:'100%' }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <img src={stagLogoImg} style={{ height: '8em'}} alt="PSA Logo" /> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            UNDER
                                        </td>
                                    </tr>
                                    <tr>    
                                        <td>
                                            CONSTRUCTION
                                        </td>
                                    </tr>
                                    <tr>    
                                        <td>
                                            <img src={progressBar} style={{ width:'20em' }} alt="PSA Logo" /> 
                                        </td>
                                    </tr>
                                </tbody>
                            </table>    
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default SiteDown;