import React from 'react';
import Header from '../../Header.js';
import Section from '../section/Section.js';
import background from '../../assets/website-chess-background-4.png';
import tfer from '../../assets/KnowledgeTfer.png'
import Footer from '../Footer/Footer.js';
import Pagepart from '../Pagepart/Pagepart.js';

function Training() {
    return (
      <>
        <Header />
        <Section  title = "Software Architecture Learning"
                  width = "80vw"
                  borderTop = "solid 2px red"
                  background = {background}
                  middleSectionText = "Engage in Comprehensive and Insightful Learning through Informal Training Sessions. Experience a profound exchange of knowledge and insights through our Coaching program's informal training sessions. We offer a structured learning environment where valuable expertise is transferred from our experienced Senior Architects to aspiring Senior Developers and junior Architects."
                  lowerSectionHeading
                  lowerSectionText
         />
        <div id='spacerDive' style={{ height: '4vh' }}/>
        <Pagepart titeFontSize="18pt" 
                  width = "80vw"
                  title = "Learning" 
                  ignoreBodyText={ true } />
        <Pagepart titeFontSize="16pt" 
                  width = "80vw"
                  title = "Remote Learning" 
                  bodyText="Our Learning sessions take the form of informal training, including but not limited to programming exercises, software and data design exercises, software and data systems 
                    architecture scenarios and so on. These sessions are adaptive and focus on the Student’s growth areas" />


        <Pagepart titeFontSize="18pt" 
                  width = "80vw"
                  title = "Seminars" 
                  ignoreBodyText={ true } />

        <Pagepart titeFontSize="16pt" 
                  width = "80vw"
                  title = "On-site Seminars"  
                  bodyText="An on-site seminar where a PATTON Co. Senior Architect delivers a concentrated one- or two-day (or longer if requested) Coaching and Learning session with two or more mentees to give them an extra boost. 
                    It’s suggested that this option be exercised when mentees are preparing to apply for their Architect Interviews. This option requires a minimum of two mentees." />
        <Pagepart ignoreTitle={ true }
                  width = "80vw"
                  bodyText= {
                  <ul>
                    <li>A Coaching and Learning seminar.</li>
                    <li>Delivered on-site.</li>
                    <li>A minimum of two attendees.</li>
                    <li>Priced per day per Student.</li>
                    <li>Excludes all travel, venue hire, and accommodation costs.</li>
                  </ul>

                  } />

        <Pagepart titeFontSize="16pt" 
                  width = "80vw"
                  title = "Remote Architecture Case-study talks" 
                  bodyText="An hour-long talk, delivered via MS Teams, covering a case study of an architecture from past experiences in Banking, Marketing or the Stock Exchange. These sessions are adaptive and
                     focus on the areas where the audience shows an interest." />
        <Pagepart ignoreTitle={ true }
                  width = "80vw"
                  bodyText= {
                  <ul>
                    <li>An hour-long talk about one or more architectures from past experiences.</li>
                    <li>Delivered remotely via MS Teams.</li>
                    <li>Priced per day per Student.</li>
                  </ul>

                  } />
        <Pagepart ignoreTitle={ true }
                  bodyText= {
                  <img style={{ width: "78vw"}} src={tfer} />
                  } />
        <Footer />
      </>
    );
}

export default Training;