import React from 'react';
import Header from '../../Header.js';
import Section from '../section/Section.js';
import background from '../../assets/website-chess-background-4.png';
import coaching_footer_visual from '../../assets/Coachingfooter.png';
import Footer from '../Footer/Footer.js';
import Pagepart from '../Pagepart/Pagepart.js';
import { NavLink } from 'react-router-dom';

function Coaching() {
    return (
      <>
        <Header />
        <Section  background = {background}
                  borderTop = "solid 2px red"
                  title = "Coaching Matters"
                  middleSectionText = "Elevate Your Architectural Journey with Coaching from a PATTON Co. Senior Architect. If you're a Senior Developer aspiring to transition into an Architect role or a Junior Architect seeking to enhance your skills, our Coaching program led by seasoned Senior Architects at PATTON Co. is an exceptional opportunity to accelerate your professional growth."
                  lowerSectionHeading = ""
                  lowerSectionText = ""
         />

        <div id='spacerDive' style={{ height: '4vh' }}/>

         <Pagepart titeFontSize="26pt" 
                  title = "Architecture Coaching" 
                  width = "80vw"
                  bodyText={
                    <>
                    Our Coaching sessions are adaptive, and their focus is on the actual work that the Student is engaged in. Each sessions aim will be to develop the Mentee’s technical 
                    and architecture growth areas, as well as tackling stakeholder engagement, communication and management.
                    For more information on our syllabus please <NavLink to="/Contact">Contact Us</NavLink>.
                    <div style={{ paddingTop: '10px' }}>The following options detail the various methods of engaging us for Coaching services:</div>
                    </>
                  }/>

          <Pagepart titeFontSize="18pt" 
                    width = "80vw"
                  title = "Remote Focused, One-on-one Option" 
                  bodyText="The Coaching and Learning will be provided by a PATTON Co. Senior Architect. All sessions are conducted fully remotely via MS Teams. 
                  The Coaching is more focused because there are two, one hour Coaching sessions a week." />
          <Pagepart ignoreTitle={ true }
                      width = "80vw"
                      bodyText= {
                  <ul>
                    <li>One Student in a session.</li>
                    <li>Delivered remotely via MS Teams.</li>
                    <li>8 x 1 hour remote Coaching sessions per month per Student.</li>
                  </ul>
                  } />
         
          <Pagepart titeFontSize="18pt" 
                  width = "80vw"
                  title = "Remote Standard, One-on-one Option" 
                  bodyText="The Coaching and Learning will be provided by a PATTON Co. Senior Architect. The Coaching and Knowledge 
                    Transfer sessions are conducted fully remotely via MS Teams and the Coaching is slightly less intense because there is one, one hour Coaching session a week." />
          <Pagepart ignoreTitle={ true }
                      width = "80vw"
                      bodyText= {
                  <ul>
                    <li>One Student in a session.</li>
                    <li>Delivered remotely via MS Teams.</li>
                    <li>4 x 1 hour remote Coaching sessions per month per Student.</li>
                  </ul>
                  } />
          <Pagepart ignoreTitle={ true }
                  bodyText= {
                  <img style={{ width: "80vw"}} src={coaching_footer_visual} />
                  } />
            
         <Footer />
      </>
    );
}

export default Coaching;