import React from "react";
import rookLogoImg from './assets/Rook-Logo-4.1.svg';
import stagLogoImg from './assets/Stag-Logo v20.3.svg';
import stagLogoWhiteImg from './assets/Stag-Logo v14-.png';
import PCoLogo from './assets/Stag-Logo v20.3-scaled.png';
import { useMediaPredicate } from "react-media-hook";

import { NavLink } from 'react-router-dom';
import './Header.css';


const stagLogo = true;
const whiteStagLogo = true;

function Menu() {
    var onDesktop = useMediaPredicate("(min-width: 400px)");   
    if(onDesktop)
    {
    {/*
      RESPONSIVE DESKTOP SECTION FOLLOWS
    */}        
    return (
        <div >
            <table>
                <tr>
                    <td style={{ paddingLeft: '2em', height: '50pt', verticalAlign: 'bottom' }} >
                        <div>
                            <NavLink className="menuUnderscore"
                                to="/Home"
                                activeStyle={{ color: "Gray" }}
                                style={{ textDecoration: 'none', cursor: 'pointer', color: 'white', backgroundColor: '#363636' }}>
                                Home
                            </NavLink>
                            <div style={{ height: '1.3em', width: '100%', backgroundColor: 'white', verticalAlign: 'top' }} >

                                <div style={{ height: '1.3em', backgroundColor: '#363636', verticalAlign: 'bottom' }} />

                            </div>
                        </div>
                    </td>
                    <td style={{ paddingLeft: '2em', height: '50pt', verticalAlign: 'bottom' }} >
                        <div>
                            <NavLink className="menuUnderscore"
                                to="/Consulting"
                                activeStyle={{ color: "Gray" }}
                                style={{ textDecoration: 'none', cursor: 'pointer', color: 'white', backgroundColor: '#363636' }}>
                                Consulting
                            </NavLink>
                            <div style={{ height: '1.3em', width: '100%', backgroundColor: 'white', verticalAlign: 'top' }} >

                                <div style={{ height: '1.3em', backgroundColor: '#363636', verticalAlign: 'bottom' }} />

                            </div>
                        </div>
                    </td>
                    <td style={{ paddingLeft: '2em', height: '50pt', verticalAlign: 'bottom' }}>
                        <NavLink to="/Coaching"
                            activeStyle={{ color: "Gray" }}
                            style={{ textDecoration: 'none', cursor: 'pointer', color: 'white' }}>Coaching</NavLink>
                        <div style={{ height: '1.3em', width: '100%', backgroundColor: 'white', verticalAlign: 'top' }} >
                            <div style={{ height: '1.3em', backgroundColor: '#363636', verticalAlign: 'bottom' }} />
                        </div>
                    </td>
                    <td style={{ paddingLeft: '2em', height: '50pt', verticalAlign: 'bottom' }}>
                        <NavLink to="/KnowledgeTransfer"
                            activeStyle={{ color: "Gray" }}
                            style={{ textDecoration: 'none', cursor: 'pointer', color: 'white' }}>Learning</NavLink>
                        <div style={{ height: '1.3em', width: '100%', backgroundColor: 'white', verticalAlign: 'top' }} >
                            <div style={{ height: '1.3em', backgroundColor: '#363636', verticalAlign: 'bottom' }} />
                        </div>
                    </td>
                    <td style={{ paddingLeft: '2em', height: '50pt', verticalAlign: 'bottom' }}>
                        <NavLink to="/Contact"
                            activeStyle={{ color: "Gray" }}
                            style={{ textDecoration: 'none', cursor: 'pointer', color: 'white' }}>Contact</NavLink>
                        <div style={{ height: '1.3em', width: '100%', backgroundColor: 'white', verticalAlign: 'top' }} >
                            <div style={{ height: '1.3em', backgroundColor: '#363636', verticalAlign: 'bottom' }} />
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    );
    }
    else
    {
    {/*
      RESPONSIVE MOBILE SECTION FOLLOWS
    */}
    return (
        <div >
            <table>
                <tr>
                    <td style={{ paddingLeft: '2em', height: '50pt', verticalAlign: 'bottom' }} >
                      
                    </td>
                   
                </tr>
            </table>
        </div>
    );
    }
}

function Logo() {
    if (stagLogo) {
        return StagLogo();
    }
    else {
        return RookLogo();
    }
}

function StagLogo() {
    if (whiteStagLogo) {
        return (
            <div>
                <NavLink to="/" style={{ textDecoration: 'none', color: 'white' }} >
                    <img src={stagLogoImg} style={{ width: '30px' }} alt="PSA Logo" title="The PATTON Company" />
                </NavLink>
            </div>
        );
    }
    else {
        return (
            <img src={stagLogoWhiteImg} style={{ width: '60px', height: '25%' }} alt="PSA Logo" />
        );
    }

}

function RookLogo() {
    return (
        <img src={rookLogoImg} style={{ width: '45px', height: '25%' }} alt="PSA Logo" />
    );
}


function Header() {
    return (
        <div className="Header">
            <table className="logoBox" style={{ height: '50pt' }}>
                <tbody>
                    <tr>
                        <td>
                            <Logo />
                        </td>
                        <td>
                            <NavLink to="/" style={{ textDecoration: 'none', color: 'white' }} >
                                <img src={PCoLogo} style={{ width: '100px' }} alt="PSA Logo" />
                            </NavLink>
                        </td>
                        <td style={{ width: '100%', backgroundColor: '#363636', borderStyle: 'none', borderColor: 'purple' }}>
                            <Menu />
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    );
}

export default Header;