import React from 'react';
import Header from '../../Header.js';
import Section from '../section/Section.js';
import background from '../../assets/website-chess-background-4.png';
import Footer from '../Footer/Footer.js';
import Pagepart from '../Pagepart/Pagepart.js';
import { useMediaPredicate } from "react-media-hook";

function ContactUs() {
  var onDesktop = useMediaPredicate("(min-width: 400px)");   
  if(onDesktop)
  {
    {/*
      RESPONSIVE DESKTOP SECTION FOLLOWS
    */}
    return (
      <>
        <Header />
        <Section  title = "Contact Us: How can we help?"
                  middleSectionText = "See below for the ways to get in touch."
                  borderTop = "solid 2px red"
                  background=""
                  sectionHeight = "40vh"
                  lowerSectionHeading
                  lowerSectionText
         />
        <div>
          <table style={{paddingLeft: '5vw'}}>
            <tbody>
              <tr>
                <td>
                    <div style={{ border: 'solid 1px gray', width: '20vw' }}>
                      <div style={{fontSize: '18pt' ,paddingLeft: '3vw', paddingTop: '3vh'}}>
                        Phone
                      </div>
                      <div style={{paddingLeft: '3vw', paddingTop: '2vh'}}>
                        Would you like to hire us? 
                      </div>
                      <div style={{paddingLeft: '3vw', paddingTop: '2vh', paddingBottom: '3vh'}}>
                        +27834777801
                      </div>
                    </div>
                  </td>
                <td>
                <div style={{ border: 'solid 1px gray', width: '20vw' }}>
                      <div style={{fontSize: '18pt' ,paddingLeft: '3vw', paddingTop: '3vh'}}>
                        Email
                      </div>
                      <div style={{paddingLeft: '3vw', paddingTop: '2vh'}}>
                       Questions and comments.
                      </div>
                      <div style={{paddingLeft: '3vw', paddingTop: '2vh', paddingBottom: '3vh'}}>
                       <div id='txtHolder1' onLoad={constructIt('txtHolder1') }></div>
                      </div>
                    </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>


        <Pagepart titeFontSize = "20pt" 
                      fontSize = "14pt"
                      title = "" 
                      footerPadding = "12.2vh"
                  bodyText={
                    <>
     
                    </>
                  }/>



         <Footer />
      </>
    );
  }
  else
  {
    return (
      <>
        <Header />
        <Section  title = "Contact Us: How can we help?"
                  middleSectionText = "See below for the ways to get in touch."
                  borderTop = "solid 1px red"
                  background=""
                  sectionHeight = "40vh"
                  lowerSectionHeading
                  lowerSectionText
         />
        <div>
          <table style={{paddingLeft: '5vw'}}>
            <tbody>
              <tr>
              <td>
                    <div style={{ border: 'solid 1px gray', width: '90vw' }}>
                      <div style={{fontSize: '18pt' ,paddingLeft: '3vw', paddingTop: '3vh'}}>
                        Phone
                      </div>
                      <div style={{paddingLeft: '3vw', paddingTop: '2vh'}}>
                        Would you like to hire us? 
                      </div>
                      <div style={{paddingLeft: '3vw', paddingTop: '2vh', paddingBottom: '3vh'}}>
                        +27834777801
                      </div>
                    </div>
                  </td>
                </tr>
              <tr>
                
                <td>
                <div style={{ border: 'solid 1px gray', width: '90vw' }}>
                      <div style={{fontSize: '18pt' ,paddingLeft: '3vw', paddingTop: '3vh'}}>
                        Email
                      </div>
                      <div style={{paddingLeft: '3vw', paddingTop: '2vh'}}>
                       Questions and comments.
                      </div>
                      <div style={{paddingLeft: '3vw', paddingTop: '2vh', paddingBottom: '3vh'}}>
                        <div id='txtHolder2' onLoad={constructIt('txtHolder2')}></div>
                      </div>
                    </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>


        <Pagepart titeFontSize = "20pt" 
                      fontSize = "14pt"
                      title = "" 
                      footerPadding = "12.2vh"
                  bodyText={
                    <>
     
                    </>
                  }/>



         <Footer />
      </>
    );
  }
}

function constructIt(id)
{
  var el = document.getElementById(id);
  if(el != null)
  {
    el.innerHTML = "i"+ "n" + "f" + "o" + "@" + "p" + "a" + "t" + "t"+"o"+"n" + ".co"+"."+"za";
  }

}

export default ContactUs;