import './App.css';
import Consulting from './components/consulting/Consulting.js';
import Coaching from './components/Coaching/Coaching';
import KnowledgeTransfer from './components/KnowledgeTransfer/KnowledgeTransfer';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SiteDown from './components/siteDown/SiteDown';
import Home from './components/home/Home';
import ContactUs from './components/Contact/Contact';

function App() {


  return (
    <>
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/Consulting" element={ <Consulting /> } />
        <Route path="/Coaching" element={ <Coaching /> } />
        <Route path="/KnowledgeTransfer" element={ <KnowledgeTransfer /> } />
        <Route path="/Contact" element={ <ContactUs /> } />
        <Route path="*" element={ <Home /> } /> 
        {/* <Route path="*" element={ <SiteDown /> } />  */}
      </Routes>
    </>
  );
}

export default App;
