
function Pagepart(props) {
    if(props.ignoreTitle)
    {
        return (
            <div className="Pagepart" style={{  height: props.height,
                                                paddingTop: props.paddingTop,
                                                color: props.color,
                                                paddingLeft: '5vw',
                                                backgroundSize:'cover', 
                                                backgroundImage: `url(${props.background})`,
                                                backgroundColor: props.backgroundColor }} >
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td><div id='spacerDive' style={{ height: props.headerPadding }}/></td>
                        </tr>
                        <tr>
                            <td style={{fontFamily:'helvetica', fontSize: props.fontSize, paddingLeft: '10px' } }>{props.bodyText}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        );
    }
    else
    {
        if(props.ignoreBodyText)
        {
            return (
                <div className="Pagepart" style={{  width: props.width,
                                                    height: props.height,
                                                    paddingTop: props.paddingTop,
                                                    color: props.color,
                                                    paddingLeft: '5vw',
                                                    backgroundSize:'cover', 
                                                    backgroundImage: `url(${props.background})`,
                                                    backgroundColor: props.backgroundColor }} >
                <div>
                    <table style={{borderBottom: props.borderBottom}}>
                        <tbody>
                            <tr>
                                <td><div id='spacerDive' style={{ height: props.headerPadding }}/></td>
                            </tr>
                            <tr>
                                <td style={{fontFamily:'helvetica', fontSize: props.titeFontSize, padding: '10px', textAlign:'left' }}>{props.title}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            );
        }
        else {
            return (
                <div className="Pagepart" style={{  width: props.width,
                                                    height: props.height,
                                                    paddingTop: props.paddingTop,
                                                    color: props.color,
                                                    paddingLeft: '5vw',
                                                    backgroundSize:'cover', 
                                                    backgroundImage: `url(${props.background})`,
                                                    backgroundColor: props.backgroundColor }} >
                <div>
                    <table style={{borderBottom: props.borderBottom}}>
                        <tbody>
                            <tr>
                                <td><div id='spacerDive' style={{ height: props.headerPadding }}/></td>
                            </tr>
                            <tr>
                                <td style={{fontFamily:'helvetica', fontSize: props.titeFontSize, padding: '10px', textAlign:'left' }}>{props.title}</td>
                            </tr>
                            <tr >
                                <td style={{fontFamily:'helvetica', fontSize: props.fontSize, paddingLeft: '10px' } }>{props.bodyText}</td>
                            </tr>
                            <tr>
                                <td style={{fontFamily:'helvetica', fontSize: props.fontSize, paddingLeft: '10px', height: props.footerPadding } }></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            );
        }
        
    }

}

export default Pagepart;