import React from 'react';

function ContentSection(props){
    return(
        <div className="ContentSection" style={{  backgroundColor: 'white' }}>
            <table>
                <tbody>
                    <tr>
                        <td style={{fontFamily:'helvetica', fontSize:'33pt', padding: '10px', textAlign:'center' }}>
                            
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontFamily:'helvetica', fontSize:'33pt', padding: '10px', textAlign:'center' }}>{props.title}</td>
                    </tr>
                    <tr>
                        <td style={{fontFamily:'helvetica', fontSize:'12pt', paddingLeft: '10px' } }>{props.bodyText}</td>
                    </tr>
                    <tr>
                        <td style={{fontFamily:'helvetica', fontSize:'12pt', paddingLeft: '10px' } }>
                            {props.bodyContent}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default ContentSection;