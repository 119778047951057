import React from 'react';
import Header from '../../Header.js';
import Section from '../section/Section.js';
import background from '../../assets/website-chess-background-4.png';
import Footer from '../Footer/Footer.js';
import Pagepart from '../Pagepart/Pagepart.js';
import { NavLink } from 'react-router-dom';
import Consulting_footer from '../../assets/Step_into_the_world_of_limitless_possibilities_with_o_1262a4c9-4b86-4d0a-91fc-435a241933c1.png'
import Consulting_footer2 from '../../assets/consulting2.png'

function Consulting() {
    return (
      <>
        <Header />
        <Section  title = "Responseive Agile Software-Architecture"
                  borderTop = "solid 2px red"
                  middleSectionText = "PATTON Co. carefully makes practical software design choices with ease of use, maintenance and testability top of mind."
                  background={background}
                  lowerSectionHeading
                  lowerSectionText
         />
        <div id='spacerDive' style={{ height: '4vh' }}/>

        <Pagepart titeFontSize = "20pt" 
                      fontSize = "14pt"
                      title = "Software architecture consulting services" 
                      width = "80vw"
                      footerPadding = "5.5vh"
                  bodyText={
                    <>
                      <p>PATTON Company's architecture consulting services range from standard small scale system design through to complex multi-system, enterprise architectures. </p>

                      <p>Ready to take your software projects to new heights?</p> Partner with PATTON Company and leverage our expert software architecture consulting services. Together, we'll build a robust, scalable, and future-ready architecture that accelerates your business success.
                      Contact us today to schedule a consultation and discover how our consulting services can transform your software projects.

                      <p>Contact us to <NavLink to="/Contact" 
                                activeStyle={{ color: "Gray" }}
                                style={{ textDecoration: 'none', cursor: 'pointer', color: 'grey' }} >
                            <span style={{ textDecoration: 'underline' }}>Learn more.</span>
                            </NavLink> 
                      </p>
                    </>
                  }/>

          <Pagepart ignoreTitle={ true }
                  bodyText= {
                  <img style={{ width: "78vw"}} src={Consulting_footer2} />
                  } />


         <Footer />
      </>
    );
}

export default Consulting;